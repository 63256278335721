import React from 'react';
import Loadable from 'react-loadable';
import {
  ComponentPreview,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import headlinePreviewData from '../../../../data/previews/headline.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const Headline = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Headline;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Headline"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Type" tierThree="Headline" />
      <V5Notice
        componentName="Headline"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/typography-headline--docs"
      />

      <PageNavigation links={['Size', 'Small Screens', 'Usage', 'Platform']} />

      <Section title="Size">
        <Paragraph>
          The level of headline you select should provide the appropriate
          hierarchy and visual coherency within the interface.
        </Paragraph>
        <ComponentPreview
          name="HeadlineSize"
          layout="default"
          previewData={headlinePreviewData.levels}>
          <Headline>We help coaches and athletes win with video.</Headline>
        </ComponentPreview>
      </Section>

      <Section title="Small Screens">
        <Paragraph>
          If you use the headline component, headlines automatically decrease in
          size for screens smaller than 600px. If you’re designing exclusively
          for smaller screens, use smaller type sizes and manually provide those
          specifications to your developer.
        </Paragraph>
      </Section>

      <Section title="Usage">
        <Paragraph>
          Use our{' '}
          <Link href="http://hudl.com/design/writing/writing-for/teams/product">
            UI label guidelines
          </Link>{' '}
          when typesetting headlines.
        </Paragraph>
        <List>
          <li>Never set headlines in all caps.</li>
          <li>Keep headlines short, sweet and to the point.</li>
        </List>
      </Section>

      <Section title="Platform">
        <PlatformTable />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
